import * as turf from "@turf/turf"

function getAllPointsOnLine(selectedLineStringCoords, draw) {
  const selectedLineString = turf.lineString(selectedLineStringCoords)
  const existingPoints = draw
    .getAll()
    .features.filter((feature) => feature.geometry.type === "Point")
  const featureCollection: any = []
  const epsilon = 0.00005
  for (const feature of existingPoints) {
    const point = turf.point(feature.geometry.coordinates)
    const isPointOnLine = turf.booleanPointOnLine(point, selectedLineString, {
      epsilon,
    })
    if (isPointOnLine) {
      featureCollection.push(feature)
    }
  }
  return featureCollection
}

export { getAllPointsOnLine }
